<template>
    <ContentWrapper>
        <breadcrumb/>

        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="card card-default animated-fast fadeInDown">
                        <div class="card-header">
                            {{$t('wallet.view.order_bank_details.heading')}} {{ order.label }}
                        </div>
                        <div class="card-body">

                            <b-row>
                                <b-col sm="6">
                                    <p>
                                        Here you can find our Bank-Details, you can always pay your invoices by bank transfer.
                                        You can reopen this view in the order overview all time.
                                    </p>
                                </b-col>
                                <b-col sm="6">
                                    <p>
                                        Payments made via bank transfer need 3-5 (working) days until it arrive. We check our bank account daily for payments.
                                    </p>
                                </b-col>
                            </b-row>

                            <b-select v-model="selectedArea">
                                <option selected value="" disabled>{{$t('wallet.view.order_bank_details.currency.default')}}</option>
                                <option value="eu">{{$t('wallet.view.order_bank_details.currency.eur')}}</option>
                                <option value="swiss">{{$t('wallet.view.order_bank_details.currency.chf')}}</option>
                            </b-select>

                            <b-row v-if="selectedArea === 'eu'" class="mt-3">
                                <b-col md="6">
                                    <div class="d-inline-block border m-auto p-5 text-left bg-white">
                                        <div>{{$t('wallet.view.order_bank_details.beneficiary')}}</div>
                                        <div class="h4">CANNERALD AG</div>
                                        <div>IBAN</div>
                                        <div class="h4">CH44 0900 0000 1540 7590 0</div>
                                        <span class="text-warning"><i class="far fa-exclamation-triangle"></i> {{ $t('wallet.view.order_bank_details.hint_iban', {currency: 'EUR'}) }}</span>
                                        <div>BIC</div>
                                        <div class="h4">POFICHBEXXX</div>
                                        <div>Recipient address</div>
                                        <div class="h4">
                                        Cannerald AG, Kirchgasse 30, 3312 Fraubrunnen
                                        </div>
                                        <div>Bank / Payment institution</div>
                                        <div class="h4">PostFinance AG</div>
                                    </div>
                                </b-col>
                                <b-col md="4">
                                    <div>Amount:</div>
                                    <div class="h4 b p-2">
                                        {{order.exchanged.EUR | currency}} EUR
                                    </div>
                                    <p><a href="" @click.prevent="selectedArea = 'swiss'"><i class="far fa-arrow-right mr-2"></i>Währung in CHF wechseln <br/>(Achtung IBAN ändert sich!)</a></p>
                                    <span class="text-warning"><i class="far fa-exclamation-triangle"></i> {{ $t('wallet.view.order_bank_details.hint_amount') }} </span>
                                    <div class="mt-3">Additional Informations:</div>
                                    <div class="h4 b p-2">Invoice {{ order.label }}</div>
                                    <span class="text-warning"><i class="far fa-exclamation-triangle"></i> {{ $t('wallet.view.order_bank_details.hint_invoice') }}</span>
                                </b-col>
                            </b-row>

                            <b-row v-if="selectedArea === 'swiss'" class="mt-3">
                                <b-col md="6">
                                    <div class="d-inline-block border m-auto p-5 text-left bg-white">
                                        <div>{{$t('wallet.view.order_bank_details.beneficiary')}}</div>
                                        <div class="h4">CANNERALD AG</div>
                                        <div>IBAN</div>
                                        <div class="h4">CH88 0900 0000 1540 7588 4</div>
                                        <span class="text-warning"><i class="far fa-exclamation-triangle"></i> {{ $t('wallet.view.order_bank_details.hint_iban', {currency: 'CHF'}) }}</span>
                                        <div>BIC</div>
                                        <div class="h4">POFICHBEXXX</div>
                                        <div>Recipient address</div>
                                        <div class="h4">
                                        Cannerald AG, Kirchgasse 30, 3312 Fraubrunnen
                                        </div>
                                        <div>Bank / Payment institution</div>
                                        <div class="h4">PostFinance AG</div>
                                    </div>
                                </b-col>
                                <b-col md="4">
                                    <div>Amount:</div>
                                    <div class="h4 b p-2">
                                        {{order.exchanged.CHF | currency}} CHF
                                    </div>
                                    <p><a href="" @click.prevent="selectedArea = 'eu'"><i class="far fa-arrow-right mr-2"></i>Währung in EUR wechseln <br/>(Achtung IBAN ändert sich!)</a></p>
                                    <span class="text-warning"><i class="far fa-exclamation-triangle"></i> {{ $t('wallet.view.order_bank_details.hint_amount') }} </span>
                                    <div class="mt-3">Additional Informations:</div>
                                    <div class="h4 b p-2">Invoice {{ order.label }}</div>
                                    <span class="text-warning"><i class="far fa-exclamation-triangle"></i> {{ $t('wallet.view.order_bank_details.hint_invoice') }}</span>
                                </b-col>
                            </b-row>

                            <div class="btn-toolbar mt-3 justify-content-end">
                                <b-button variant="primary" :to="{name: 'Wallet:Order', order_label: order.label}">Back to
                                    Order
                                </b-button>
                                <b-button variant="primary" class="ml-2"
                                          :to="{name: 'Wallet:Order', params: {order_label: order.label, status: 'success'}}">
                                    Mark as paid
                                </b-button>
                            </div>
                        </div>
                    </div>
                    <div class="card card-default animated-fast fadeInUp">
                        <div class="card-header">
                            {{$t('wallet.view.order_bank_details.cash_payment.header')}}
                        </div>
                        <div class="card-body">
                            {{$t('wallet.view.order_bank_details.cash_payment.description')}}
                            <div class="text-right mt-3">
                                <!--b-btn class="mr-2" :to="{name: 'Appointment:Appointments'}">{{ $t('wallet.view.order_bank_details.cash_payment.book_tour') }}</b-btn-->
                                <b-btn :to="{name: 'Support:Contact'}">{{ $t('wallet.view.order_bank_details.cash_payment.request_meeting') }}</b-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </ContentWrapper>
</template>
<style lang="scss">

</style>
<script>
    export default {
        data() {
            return {
                exchangeRates: {},
                selectedArea: '',
                order: {
                    label: '',
                    status: '',
                    created_at: '',
                    amount: 0,
                    paid_at: 0,
                    type: '',
                    address: {
                        firstname: '',
                        lastname: '',
                        country: '',
                        town: '',
                        zip: '',
                    }
                },
            }
        },
        mounted() {
            this.$api.get('wallet/orders/' + this.$route.params.order_label).then(response => {
                this.order = response.data.order;
                this.exchangeRates = response.data.exchangeRates;
                this.address = response.data.address;
            });
        },
        methods: {}
    }
</script>
